import React from "react";
import { Helmet } from "react-helmet";
import { graphql, PageProps } from "gatsby";
import { FiDownloadCloud } from "@react-icons/all-files/fi/FiDownloadCloud";

import Layout from "../components/layout";
import PageTitle from "../components/pageTitle";

type PuzzelsPageType = {
  allFile: {
    edges: [
      {
        node: {
          publicURL: string;
          relativePath: string;
        };
      }
    ];
  };
};

export default function PuzzelsPage({
  data: { allFile },
}: PageProps<PuzzelsPageType>): JSX.Element {
  const pdfs = allFile.edges;

  return (
    <Layout>
      <Helmet>
        <title>Polle Kinderboek - Puzzels</title>
      </Helmet>
      <PageTitle>Puzzels</PageTitle>
      <div className="grid xl:grid-cols-3 font-polle">
        {pdfs.map((edge, index) => {
          // Clean up the .pdf title
          let words: string[] = edge.node.relativePath.split("_");
          let capitalized: string[] = words.map((element) => {
            let word: string = element.replace(".pdf", "");
            return (
              word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()
            );
          });
          const title: string = capitalized.join(" ");

          // Calculate even/uneven index for background switch
          const backgroundColor: string =
            index % 2 == 0 ? "bg-yellow-polle" : "bg-blue-polle";

          return (
            <a
              href={edge.node.publicURL}
              download
              className="m-10 transition-transform duration-500 hover:scale-110"
            >
              <div className={`rounded-lg shadow-md p-5 ${backgroundColor}`}>
                <div className="flex flex-row space-x-5 items-center">
                  <FiDownloadCloud size={50} />

                  <h1 className="text-2xl">{title}</h1>
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query PuzzelsPage {
    allFile(filter: { extension: { glob: "pdf" } }) {
      edges {
        node {
          publicURL
          relativePath
        }
      }
    }
  }
`;
